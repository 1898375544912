import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import PricingImage from "../../assets/pricing.png";
import PricingImageMobile from "../../assets/pricingMobile.png";
import { Helmet } from "react-helmet";
import { MyContext } from "../../Context/MyContext";
import "./Pricing.css";
import { Button } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import classes from "../../Components/PlainHeroSection/MainImage.module.css";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
  },
}));

const costs = {
  ["IB DP"]: {
    ["PAYL"]: {
      ["Foundation"]: { ["hourly"]: 50.99 },
      ["Advance"]: { ["hourly"]: 65.99 },
      ["Expert"]: { ["hourly"]: 75.99 },
    },
    ["8-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 45.99,
        ["total"]: 367.92,
      },
      ["Advance"]: {
        ["hourly"]: 60.99,
        ["total"]: 487.92,
      },
      ["Expert"]: {
        ["hourly"]: 70.99,
        ["total"]: 567.92,
      },
    },
    ["12-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 40.99,
        ["total"]: 491.88,
      },
      ["Advance"]: {
        ["hourly"]: 55.99,
        ["total"]: 671.88,
      },
      ["Expert"]: {
        ["hourly"]: 65.99,
        ["total"]: 791.88,
      },
    },
    ["16-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 35.99,
        ["total"]: 575.84,
      },
      ["Advance"]: {
        ["hourly"]: 50.99,
        ["total"]: 815.84,
      },
      ["Expert"]: {
        ["hourly"]: 60.99,
        ["total"]: 975.84,
      },
    },
  },
  ["IB MYP"]: {
    ["PAYL"]: {
      ["Foundation"]: { ["hourly"]: 40.99 },
      ["Advance"]: { ["hourly"]: 55.99 },
      ["Expert"]: { ["hourly"]: 65.99 },
    },
    ["8-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 35.99,
        ["total"]: 287.92,
      },
      ["Advance"]: {
        ["hourly"]: 50.99,
        ["total"]: 407.92,
      },
      ["Expert"]: {
        ["hourly"]: 60.99,
        ["total"]: 487.92,
      },
    },
    ["12-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 30.99,
        ["total"]: 371.88,
      },
      ["Advance"]: {
        ["hourly"]: 45.99,
        ["total"]: 551.88,
      },
      ["Expert"]: {
        ["hourly"]: 55.99,
        ["total"]: 671.88,
      },
    },
    ["16-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 25.99,
        ["total"]: 311.84,
      },
      ["Advance"]: {
        ["hourly"]: 40.99,
        ["total"]: 655.84,
      },
      ["Expert"]: {
        ["hourly"]: 50.99,
        ["total"]: 815.84,
      },
    },
  },
  ["Others"]: {
    ["PAYL"]: {
      ["Foundation"]: { ["hourly"]: 35.99 },
      ["Advance"]: { ["hourly"]: 50.99 },
      ["Expert"]: { ["hourly"]: 60.99 },
    },
    ["8-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 30.99,
        ["total"]: 247.92,
      },
      ["Advance"]: {
        ["hourly"]: 45.99,
        ["total"]: 367.92,
      },
      ["Expert"]: {
        ["hourly"]: 55.99,
        ["total"]: 447.92,
      },
    },
    ["12-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 25.99,
        ["total"]: 311.88,
      },
      ["Advance"]: {
        ["hourly"]: 40.99,
        ["total"]: 491.88,
      },
      ["Expert"]: {
        ["hourly"]: 50.99,
        ["total"]: 611.88,
      },
    },
    ["16-Hour"]: {
      ["Foundation"]: {
        ["hourly"]: 20.99,
        ["total"]: 251.84,
      },
      ["Advance"]: {
        ["hourly"]: 35.99,
        ["total"]: 575.84,
      },
      ["Expert"]: {
        ["hourly"]: 45.99,
        ["total"]: 735.84,
      },
    },
  },
};

export default function NewPricingPage() {
  const navigate = useNavigate();

  //   ========================== SEO ==========================

  const { seoData, currency, setCurrency, convertPrice, exchangeRates } =
    useContext(MyContext);
  const [thisPageSEO, setThisPageSEO] = useState({});
  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };
  useEffect(() => {
    if (seoData) {
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Pricing"));
    }
  }, [seoData]);

  //   ========================== SEO ==========================

  const [imageLoaded, setImageLoaded] = useState(false);
  const [program, setProgram] = useState("IB DP");
  const [tutorType, setTutorType] = useState("Foundation");

  const cards = [
    {
      discount: "Save 0%",
      title: "PAYL (Pay As You Learn)",
      desc: "Single Lessons",
      hours: "",
      type: "PAYL",
      paramType: "Pay As you learn",
      infoIconText:
        "Support for Independent Study - Boosting grip on subjects; suitable for self-starters",
      learningBenefits: [
        {
          icon: "tick",
          text: "One-on-one tutoring for quick problem-solving",
        },
        {
          icon: "tick",
          text: "Quick exam tips & minor assignment help",
        },
        {
          icon: "tick",
          text: "Instant concept building",
        },
        {
          icon: "tick",
          text: "Basic study materials",
        },
        {
          icon: "cross",
          text: "No long-term tracking",
        },
      ],
    },
    {
      discount: "Save 10%",
      title: "08 Hours Plan",
      desc: "The More You Learn, The More You Save! Save Up 10%",
      hours: "8-Hour",
      type: "8-Hour",
      paramType: "Lesson Plan Package",
      infoIconText:
        "Overcoming Challenges - Improving my grade - Designed for aspiring achievers",
      learningBenefits: [
        {
          icon: "tick",
          text: "Structured lessons for better understanding",
        },
        {
          icon: "tick",
          text: "More practice papers & guided assignments",
        },
        {
          icon: "tick",
          text: "More time for deep concept explanations",
        },
        {
          icon: "tick",
          text: "In-depth study guides & notes",
        },
        {
          icon: "tick",
          text: "Initial progress tracking",
        },
      ],
    },
    {
      discount: "Save 20%",
      title: "12 Hours Plan",
      desc: "Book More, Pay Less – Save Up to 20% on Your Lessons!",
      hours: "12-Hour",
      type: "12-Hour",
      paramType: "Lesson Plan Package",
      infoIconText:
        "Striving for deep understanding - Focusing on Exam Prep - Designed for high grade seekers",
      learningBenefits: [
        {
          icon: "tick",
          text: "Comprehensive study plans with goal-setting",
        },
        {
          icon: "tick",
          text: "Regular mock tests & detailed feedback",
        },
        {
          icon: "tick",
          text: "Strong problem-solving & reasoning skills",
        },
        {
          icon: "tick",
          text: "Comprehensive revision packs & materials",
        },
        {
          icon: "tick",
          text: "Consistent performance monitoring & feedback",
        },
      ],
    },
    {
      discount: "Save 30%",
      title: "16 Hours Plan",
      desc: "Upgrade to 16 Hours and Get 30% OFF Instantly!",
      hours: "16-Hour",
      type: "16-Hour",
      paramType: "Lesson Plan Package",
      infoIconText:
        "Mastering Concepts - Acing Exams - Designed for top achievers",
      learningBenefits: [
        {
          icon: "tick",
          text: "Full syllabus coverage & strategy planning",
        },
        {
          icon: "tick",
          text: "Full-length exams, past paper reviews",
        },
        {
          icon: "tick",
          text: "Advanced learning with real-world applications",
        },
        {
          icon: "tick",
          text: "Premium resources & extended learning tools",
        },
        {
          icon: "tick",
          text: "Long-term improvement strategies & adaptive learning",
        },
      ],
    },
  ];

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  //   ========================== CHECK IF MOBILE ==========================
  const [isMobile, setIsMobile] = useState(false);
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600);
  };
  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div>
      <Helmet>
        {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
      </Helmet>

      <div
        style={{
          flex: 1,
          width: "100%",
          background: "red",
          height: imageLoaded ? "max-content" : "600px",
          background: "#14549f",
        }}
      >
        <div style={{ maxWidth: "1800px", flex: 1, margin: "0px auto" }}>
          {isMobile ? (
            <img
              src={PricingImageMobile}
              width="100%"
              alt=""
              onLoad={() => setImageLoaded(true)}
              height="auto"
              style={{ maxWidth: "1800px" }}
            />
          ) : (
            <img
              src={PricingImage}
              onLoad={() => setImageLoaded(true)}
              width="100%"
              alt=""
              height="auto"
            />
          )}
        </div>
      </div>
      <div
        style={{
          paddingTop: "2rem",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "90vw",
            maxWidth: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "2rem",
            margin: "0px auto",
            boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
            // transform: "translateY(-50%)",
            background: "#fff",
            overflow: "hidden",
            gap: "5px",
            textAlign: "center",
          }}
        >
          <div id="firstFilter" className="filter-switch">
            <input
              id="option1"
              name="options"
              type="radio"
              value="IB MYP"
              checked={program === "IB MYP"}
              onChange={(e) => setProgram(e.target.value)}
            />
            <label className="option" htmlFor="option1">
              IB MYP
            </label>

            <input
              id="option2"
              name="options"
              type="radio"
              value="IB DP"
              checked={program === "IB DP"}
              onChange={(e) => setProgram(e.target.value)}
            />
            <label className="option" htmlFor="option2">
              IB DP
            </label>

            <input
              id="option3"
              name="options"
              type="radio"
              value="Others"
              checked={program === "Others"}
              onChange={(e) => setProgram(e.target.value)}
            />
            <label className="option" htmlFor="option3">
              Other Intl. Curricula
            </label>

            <span className="background"></span>
          </div>
        </div>

        <div
          style={{
            padding: "2rem 1rem",
            paddingTop: "2rem",
            margin: "0px auto",
            maxWidth: "1800px",
          }}
        >
          <div className="dropdowns-container">
            {/* ===================== EXCHANGE RATES DROP DOWN ============================== */}
            {exchangeRates && (
              <div>
                <FormControl style={{ minWidth: "300px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Choose currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currency}
                    label="Choose currency"
                    onChange={handleChange}
                  >
                    <MenuItem value={"£"}>GBP (£)</MenuItem>
                    <MenuItem value={"$"}>USD ($)</MenuItem>
                    <MenuItem value={"€"}>EUR (€)</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <div>
              <FormControl style={{ minWidth: "300px" }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Tutor Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tutorType}
                  label="Choose Tutor Level"
                  onChange={(e) => setTutorType(e.target.value)}
                >
                  <MenuItem value={"Foundation"}>Foundation Level</MenuItem>
                  <MenuItem value={"Advance"}>Advance Level</MenuItem>
                  <MenuItem value={"Expert"}>Expert Level</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          {/* ===================== EXCHANGE RATES DROP DOWN ============================== */}

          {/* ======================================= CARD =================================== */}

          <div className="pricing-container">
            {cards?.map((item, index) => (
              <div key={index} className="pricing-card">
                {/* TOP */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      padding: "5px 15px",
                      background: "#1555a0",
                      color: "#fff",
                      visibility:
                        item.discount !== "Save 0%" ? "visible" : "hidden",
                    }}
                  >
                    {item.discount}
                  </div>
                </div>

                <div style={{ width: "100%", padding: "1rem", flex: 1 }}>
                  {/* TITLE */}
                  <div
                    style={{
                      fontSize: "clamp(1.3rem, 5vw, 1.7rem)",
                      fontWeight: "bold",
                    }}
                  >
                    {item.title === "PAYL (Pay As You Learn)" ? (
                      <span>
                        PAYL{" "}
                        <span style={{ fontSize: "1rem" }}>
                          (Pay As You Learn)
                        </span>
                      </span>
                    ) : (
                      item.title
                    )}
                  </div>

                  {/* DESC */}
                  <div>
                    {item.desc === "Single Lessons" ? (
                      <div>
                        {item.desc}
                        <br />
                        <span style={{ visibility: "hidden" }}>PAYL</span>
                      </div>
                    ) : (
                      item.desc
                    )}
                  </div>

                  {/* PRICE */}
                  <div style={{ padding: "30px 0px", textAlign: "center" }}>
                    <div
                      style={{
                        fontSize: "clamp(1.1rem, 5vw, 1.5rem)",
                        fontWeight: "bold",
                      }}
                    >
                      {currency}{" "}
                      <span
                        style={{
                          fontSize: "clamp(1.5rem, 5vw, 3rem)",
                        }}
                      >
                        {convertPrice(
                          costs[program][item.type][tutorType]["hourly"],
                          currency
                        )}
                      </span>
                      per hourly session{" "}
                      <HtmlTooltip
                        title={
                          <React.Fragment>{item.infoIconText}</React.Fragment>
                        }
                      >
                        <span style={{ fontSize: "1.1rem", cursor: "pointer" }}>
                          ⓘ
                        </span>
                      </HtmlTooltip>
                    </div>{" "}
                    {/* <div>hourly sessions</div> */}
                  </div>

                  {/* INFO TEXT */}
                  <div
                    // style={{
                    //   width: "100%",
                    //   background: "rgb(208, 230, 255)",
                    //   color: "#1555a0",
                    //   padding: "10px",
                    //   textAlign: "center",
                    // }}
                    style={{
                      padding: "10px 15px",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                    className={"mainButtonPricing"}
                    onClick={() =>
                      navigate(
                        `/register?program=${encodeURIComponent(
                          program
                        )}&package=${encodeURIComponent(
                          item.paramType
                        )}&hours=${encodeURIComponent(
                          item.hours
                        )}&tutorType=${encodeURIComponent(
                          `${tutorType} Level`
                        )}`
                      )
                    }
                  >
                    Schedule Free Consultation
                  </div>

                  {/* BOOK NOW BUTTON */}
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px 0px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <button
                      onClick={() =>
                        navigate(
                          `/register?program=${encodeURIComponent(
                            program
                          )}&package=${encodeURIComponent(
                            item.paramType
                          )}&hours=${encodeURIComponent(
                            item.hours
                          )}&tutorType=${encodeURIComponent(
                            `${tutorType} Level`
                          )}`
                        )
                      }
                      className="pricingMainButton"
                      // style={{
                      //   borderColor: "#a81e1e",
                      //   color: "#a81e1e",
                      //   padding: "8px 20px",
                      //   fontSize: "1.1rem",
                      // }}
                    >
                      <AccountCircleOutlinedIcon />
                      Book Now!
                    </button>
                  </div>

                  {/* LEARNING BENEFITS */}
                  <div
                    style={{
                      padding: "15px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Learning Benefits
                    </div>
                    <div style={{ padding: "0rem 5px" }}>
                      {item.learningBenefits?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flex: 1,
                            gap: "5px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "8px",
                            marginBottom: "6px",
                            color: "#3e3e3e",
                          }}
                        >
                          <div style={{ fontSize: "0.8rem" }}>
                            {item.icon === "tick" ? (
                              <CheckCircleIcon style={{ color: "#325f52" }} />
                            ) : (
                              <CancelIcon style={{ color: "#a81e1e" }} />
                            )}
                          </div>
                          <div style={{ flex: 1 }}>{item.text}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* ======================================== CARD ================================== */}
        </div>
      </div>
    </div>
  );
}
