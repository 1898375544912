import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  TextField,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { MyContext } from "../../Context/MyContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonsGroup from "./ButtonsGroup";
import { RegisterFormContext } from "../../Context/RegisterFormContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function ChooseTutor() {
  const { formData, setFormData, two, four, setAvailableScreens } =
    useContext(RegisterFormContext);

    
      const [searchParams] = useSearchParams();
    
      
        useEffect(() => {
          const tutoringSupport = searchParams.get("tutorType") || "";
          if(tutoringSupport){
          setFormData((prevData) => ({
            ...prevData,
            tutoringSupport: tutoringSupport,
          }));
          }
        }, [searchParams]);
      

  function handlePrev() {
    if (two.current) {
      two.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  function handleNext() {
    setAvailableScreens((prev) => ({ ...prev, screen4: true }));
    setTimeout(() => {
      if (four.current) {
        four.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 300);
  }

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      tutoringSupport: event.target.value,
    }));
  };

  return (
    <div
      style={{
        marginBottom: "1rem",
        minHeight: "80vh",
        paddingTop: "5rem",
        paddingBottom: "5rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography variant="h6" sx={{ fontSize: "clamp(1.1rem, 4vw, 1.5rem" }}>
          Tutoring Support
        </Typography>

        <RadioGroup
  row
  aria-labelledby="demo-row-radio-buttons-group-label"
  name="row-radio-buttons-group"
  required
  style={{ display: "flex", gap: "2rem" }}
  value={formData.tutoringSupport || ""}
  onChange={handleChange}
>
  <FormControlLabel
    value="Foundation Level"
    control={<Radio />}
    label={
      <>
        Foundation Level{" "}
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit" style={{ fontWeight: "bold" }}>
                Tutors for Self-Starters
              </Typography>
              {
                "Our Foundation Level Tutors are accomplished IB diploma graduates who possess a wealth of knowledge and expertise, making them well-equipped to assist and guide self-motivated learners who just need support for their independent study."
              }
            </React.Fragment>
          }
        >
          <span style={{ cursor: "pointer" }}>ⓘ</span>
        </HtmlTooltip>
      </>
    }
  />

  <FormControlLabel
    value="Advance Level"
    control={<Radio />}
    label={
      <>
        Advance Level{" "}
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit" style={{ fontWeight: "bold" }}>
                Tutors for Aspiring Achievers
              </Typography>
              {
                "Meet our Advanced Level Tutors, a group of certified IB tutors and IB Worldschool teachers. They are dedicated to helping students aiming for high IB grades and mastering any challenges they are facing."
              }
            </React.Fragment>
          }
        >
          <span style={{ cursor: "pointer" }}>ⓘ</span>
        </HtmlTooltip>
      </>
    }
  />

  <FormControlLabel
    value="Expert Level"
    control={<Radio />}
    label={
      <>
        Expert Level{" "}
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit" style={{ fontWeight: "bold" }}>
                Tutors for High-Grade Seekers
              </Typography>
              {
                "At the pinnacle of our tutoring expertise, we proudly present our Expert Level Tutors. Comprising seasoned IB examiners and IB tutors, they bring a wealth of knowledge and experience for students striving for top IB grades and deep understanding in every subject."
              }
            </React.Fragment>
          }
        >
          <span style={{ cursor: "pointer" }}>ⓘ</span>
        </HtmlTooltip>
      </>
    }
  />
</RadioGroup>


        <ButtonsGroup
          nextDisabled={!formData?.tutoringSupport}
          onPrev={handlePrev}
          onNext={handleNext}
        />
      </div>
    </div>
  );
}
